<template>

    <v-app id="login">
        <v-snackbar v-model="snackbar" top right color="error">
            {{ textError }}
        </v-snackbar>
        <v-snackbar v-model="successSnakbar" top right color="success">
            {{ successMessage }}
        </v-snackbar>
        <v-main class="login-panel pt-15" :style="{ backgroundImage: `url(${backgroundUrl})` }">
            <v-container fluid class="pt-15">
                <template>
                    <v-row justify="center" class="pt-5 text-center">
                        <v-card width="400" class="mx-auto pa-4 py-3" v-if="isLoading">
                            <v-progress-circular indeterminate></v-progress-circular>
                        </v-card>
                        <v-card width="400" v-if="!isLoading && isValid" class="mx-auto pa-4 py-3">
                            <h3 class="primary--text font-weight-medium pt-5">
                                Hello, User!!
                            </h3>

                            <v-card-text class="primary--text pt-1">
                                Please enter the password, if you want to update email address?
                            </v-card-text>

                            <v-form v-model="isFormValid" ref="updatePasswordForm">
                                <loading :active="isLoading" :loader="loader" />
                                <v-row cols="12" class="pt-5" text-align="center" justify="center">
                                    <v-text-field type=" email" v-model.trim="oldEmailAddress" aria-label="E-mail"
                                        placeholder="Email" append-icon="email" :disabled="true" required outlined
                                        dense>
                                        >
                                    </v-text-field>

                                    <v-text-field :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                        :rules="[rules.required]" :type="showPassword ? 'text' : 'password'"
                                        v-model.trim="updatePasswordData.newPassword" label="Password" @keyup.enter="updateLogin"
                                        @click:append="showPassword = !showPassword" outlined hide-details>
                                    </v-text-field>
                                </v-row>
                                <v-divider class="mb-3"></v-divider> <!-- Add a divider with margin-bottom to create space -->
                                <v-card-actions class="pt-0 px-0">
                                    <v-btn color="primary" class="px-0 mx-0 btn-loging" block @click="updateLogin"
                                        :disabled="!isFormValid">UPDATE
                                    </v-btn>
                                </v-card-actions>
                            </v-form>

                        </v-card>
                        <v-card width="400" v-if="!isLoading && !isValid" class="mx-auto pa-4 py-3">
                            <h3 class="primary--text font-weight-medium pt-5">
                                Sorry!!
                            </h3>

                            <v-card-text class="primary--text pt-1">
                                {{ errorMessage }}
                            </v-card-text>
                        </v-card>
                    </v-row>
                </template>
            </v-container>
        </v-main>
    </v-app>
</template>

<script>
import authAPI from "@/services/authAPI.js";
import backgroundUrl from "../../assets/images/didpanel.jpg";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
    name: "updateEmailVerification",
    data() {
        return {
            //fullPage: false,
            snackbar: false,
            loading: false,
            textError: "",
            successSnakbar: false,
            successMessage: "",
            backgroundUrl: "",
            queryParams: {},
            loader: "bars",
            isLoading: true, // Start with loading state
            isValid: false,
            errorMessage: "",
            updatePasswordData: {},
            newEmailAddress: "",
            oldEmailAddress: "",
            isFormValid: false,
            showPassword: false,
            confirmPassword: false,
            rules: {
                required: value => !!value || "Password is Required."
            },
        };
    },
    components: {
        Loading,
    },

    methods: {
        async verifyNewLoginRecoveryLink() {
            this.isLoading = true;
            try {
                let str = window.location.search.replace("?", "");
                let arr = str.split("&");

                for (let item of arr) {
                    let split = item.split("=");
                    this.queryParams[split[0]] = split[1];
                }

                this.oldEmailAddress = decodeURIComponent(this.queryParams?.emailAddress);
                let res = await authAPI.verifyNewLoginRecoveryLink(this.queryParams);
                this.newEmailAddress = res?.newEmailAddress;
                this.isLoading = false;
                this.isValid = res?.linkValid;
            } catch (err) {
                this.errorMessage = err?.data?.messageDetail || ""
                this.isLoading = false;
            }
        },
        async updateLogin() {
            console.log("called")
            this.isLoading = true;
            try {
                const payloadForUpdatUserLogin = {
                    oldEmailAddress: this.oldEmailAddress,
                    newEmailAddress: this.newEmailAddress,
                    password: this.updatePasswordData.newPassword
                }

                console.log("payloadForUpdatUserLogin", payloadForUpdatUserLogin)
                let res = await authAPI.updateNewEmailAndUserLogin(payloadForUpdatUserLogin);

                if (res) {
                    this.successSnakbar = true;
                    this.successMessage = res?.messageDetail;
                    setTimeout(() => {
                        this.isLoading = false;
                        location.href = "/";
                    }, 3000);
                }
            } catch (error) {
                this.snackbar = true;
                this.textError = error.data.messageDetail;
                console.log("err", error)
                this.isLoading = false;
            }
        },
    },

    beforeMount() {
        this.verifyNewLoginRecoveryLink();
    },
    mounted() {
        this.backgroundUrl = backgroundUrl;
    },
};
</script>